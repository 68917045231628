import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wizard-step-container step-4" }
const _hoisted_2 = {
  key: 0,
  class: "status-message-container success"
}
const _hoisted_3 = { class: "status-icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "current-hdx-balance-message" }
const _hoisted_6 = {
  key: 1,
  class: "status-message-container error"
}
const _hoisted_7 = { class: "status-icon" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "success-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.wizardState.claiming.resultStatus === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.checkedIconSrc,
              alt: "Success"
            }, null, 8, _hoisted_4)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("h3", null, "SUCCESS!", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "success-message" }, "Welcome to HydraDX", -1)),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Current HDX Balance:", -1)),
            _createElementVNode("p", null, _toDisplayString(_ctx.hdxNewBalance), 1)
          ], 512), [
            [_vShow, +_ctx.hdxNewBalance > 0]
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.wizardState.claiming.resultStatus === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: _ctx.errorIconSrc,
              alt: "Error"
            }, null, 8, _hoisted_8)
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("h3", null, "ERROR!", -1)),
          _withDirectives(_createElementVNode("p", _hoisted_9, " Error. Please contact support. ", 512), [
            [_vShow, !_ctx.wizardState.claiming.resultMessage]
          ]),
          _withDirectives(_createElementVNode("p", { class: "success-message" }, _toDisplayString(_ctx.wizardState.claiming.resultMessage), 513), [
            [_vShow, _ctx.wizardState.claiming.resultMessage]
          ]),
          _withDirectives(_createElementVNode("p", null, "Block number: #" + _toDisplayString(_ctx.currentBlockNumber), 513), [
            [_vShow, _ctx.currentBlockNumber]
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}