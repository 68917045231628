import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hdx-progress-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['progress-line-inner-container', `current-step-${_ctx.step}`])
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("div", { class: "step-point step-1" }, null, -1),
      _createElementVNode("div", { class: "step-point step-2" }, null, -1),
      _createElementVNode("div", { class: "step-point step-3" }, null, -1),
      _createElementVNode("div", { class: "step-point step-4" }, null, -1)
    ]), 2)
  ]))
}