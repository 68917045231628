import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vModelText as _vModelText } from "vue"

const _hoisted_1 = { class: "wizard-step-container step-1" }
const _hoisted_2 = { class: "text-label" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "selected-account-view eth-account"
}
const _hoisted_6 = {
  key: 3,
  class: "text-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XhdxBalanceDetails = _resolveComponent("XhdxBalanceDetails")!
  const _component_HdxBalanceDetails = _resolveComponent("HdxBalanceDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.ethAccountData.isXhdxTotalBalanceZero)
      ? (_openBlock(), _createBlock(_component_XhdxBalanceDetails, {
          key: 0,
          "eth-account-data": _ctx.ethAccountData
        }, null, 8, ["eth-account-data"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createTextVNode(" No claim associated with address:")),
      _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.step1State.notClaimableAddress), 1),
      _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
    ], 512), [
      [_vShow, _ctx.step1State.notClaimableAddress]
    ]),
    (_ctx.ethAccountData.connectedAccount.length === 0)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          class: _normalizeClass(["hdx-btn connect-metamask", {
        disabled:
          _ctx.step1State.manuallyEnteredAccount.length > 0 ||
          !_ctx.ethAccountData.isMetamaskAvailable,
      }]),
          href: "#",
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onConnectMetamaskClick && _ctx.onConnectMetamaskClick(...args)), ["prevent"]))
        }, [
          (!_ctx.ethAccountData.isMetamaskAvailable)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Metamask is not connected"))
            : (_openBlock(), _createElementBlock("span", _hoisted_4, "Connect Metamask"))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.ethAccountData.connectedAccount.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.ethAccountData.connectedAccount), 1))
      : _createCommentVNode("", true),
    (_ctx.ethAccountData.connectedAccount.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Or Enter your ETH address "))
      : _createCommentVNode("", true),
    (_ctx.ethAccountData.connectedAccount.length === 0)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 4,
          type: "text",
          class: "hdx-input eth-addres",
          placeholder: "ETH address...",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.step1State.manuallyEnteredAccount) = $event))
        }, null, 512)), [
          [_vModelText, _ctx.step1State.manuallyEnteredAccount]
        ])
      : _createCommentVNode("", true),
    (_ctx.ethAccountData.connectedAccount.length !== 0)
      ? (_openBlock(), _createBlock(_component_HdxBalanceDetails, {
          key: 5,
          "eth-account-data": _ctx.ethAccountData
        }, null, 8, ["eth-account-data"]))
      : _createCommentVNode("", true),
    (
        _ctx.step1State.manuallyEnteredAccount.length > 0 &&
        !_ctx.ethAccountData.connectedAccount
      )
      ? (_openBlock(), _createElementBlock("a", {
          key: 6,
          class: _normalizeClass(["hdx-btn next-step", { disabled: !_ctx.step1State.manuallyEnteredAccountValid }]),
          href: "#",
          onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onConnectEthAccountClick && _ctx.onConnectEthAccountClick(...args)), ["prevent"]))
        }, _cache[7] || (_cache[7] = [
          _createTextVNode("Continue "),
          _createElementVNode("span", null, "➡", -1)
        ]), 2))
      : (_openBlock(), _createElementBlock("a", {
          key: 7,
          class: _normalizeClass(["hdx-btn next-step", { disabled: !_ctx.isNextStepValid }]),
          href: "#",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.nextStepClick && _ctx.nextStepClick(...args)), ["prevent"]))
        }, _cache[8] || (_cache[8] = [
          _createTextVNode("Next "),
          _createElementVNode("span", null, "➡", -1)
        ]), 2))
  ]))
}