import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wizard-step-container step-2" }
const _hoisted_2 = { class: "selected-account-view eth-account" }
const _hoisted_3 = {
  key: 1,
  class: "selected-account-view hydradx-account"
}
const _hoisted_4 = { class: "wizard-step-container polkadot-accounts-select" }
const _hoisted_5 = { class: "accounts-list-container" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "address" }
const _hoisted_9 = {
  key: 0,
  class: "empty-accounts-list-notice"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HdxBalanceDetails = _resolveComponent("HdxBalanceDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.ethAccountData.connectedAccount), 1),
      (!_ctx.hdxAccountData.connectedAccount)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "hdx-btn connect-metamask",
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onConnectPolkadotExtClick && _ctx.onConnectPolkadotExtClick(...args)), ["prevent"]))
          }, "Connect HydraDX account"))
        : _createCommentVNode("", true),
      (_ctx.hdxAccountData.connectedAccount)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getHydraDxFormattedAddress(_ctx.hdxAccountData.connectedAccount.address)), 1))
        : _createCommentVNode("", true),
      (_ctx.ethAccountData.connectedAccount.length !== 0)
        ? (_openBlock(), _createBlock(_component_HdxBalanceDetails, {
            key: 2,
            "eth-account-data": _ctx.ethAccountData
          }, null, 8, ["eth-account-data"]))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: _normalizeClass(["hdx-btn next-step", { disabled: !_ctx.isNextStepValid }]),
        href: "#",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.nextStepClick && _ctx.nextStepClick(...args)), ["prevent"]))
      }, _cache[3] || (_cache[3] = [
        _createTextVNode("Next "),
        _createElementVNode("span", null, "➡", -1)
      ]), 2)
    ], 512), [
      [_vShow, 
      (!_ctx.step2State.openAccountsList && !_ctx.hdxAccountData.connectedAccount) ||
      (_ctx.step2State.allAvailableAccounts.length > 0 &&
        _ctx.hdxAccountData.connectedAccount)
    ]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "list-title" }, "Accounts", -1)),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.step2State.allAvailableAccounts, (account, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            onClick: _withModifiers(() => _ctx.selectPdAccount(account), ["prevent"]),
            class: _normalizeClass(["account-item", {
          selected:
            _ctx.step2State.selectedAccount &&
            _ctx.step2State.selectedAccount.address === account.address,
        }])
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(account.meta.name), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getHydraDxFormattedAddress(account.address)), 1)
          ], 10, _hoisted_6))
        }), 128)),
        (_ctx.step2State.allAvailableAccounts.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [
              _createElementVNode("div", null, " You don't have existing accounts for HydraDX network in your Polkadot.js extension. Please, create new one or attach existing to extension. ", -1),
              _createElementVNode("br", null, null, -1),
              _createElementVNode("a", {
                target: "_blank",
                href: "https://docs.hydradx.io/claim/#02-create-hdx-address"
              }, "HELP?", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("a", {
        class: _normalizeClass(["hdx-btn select-pd-account", { disabled: !_ctx.step2State.selectedAccount }]),
        href: "#",
        onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.connectPdAccount && _ctx.connectPdAccount(...args)), ["prevent"]))
      }, "OK ", 2)
    ], 512), [
      [_vShow, _ctx.step2State.openAccountsList && !_ctx.hdxAccountData.connectedAccount]
    ])
  ], 64))
}