import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hdx-amount-indicator" }
const _hoisted_2 = { class: "total-amount" }
const _hoisted_3 = { class: "total-amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createTextVNode(" Unclaimed HDX: " + _toDisplayString(_ctx.hdxClaimableAmountFormatted.base) + " HDX ", 1),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" Unclaimed HDX 3x: " + _toDisplayString(_ctx.hdxClaimableAmountFormatted.tripled) + " HDX ", 1)
    ], 512), [
      [_vShow, _ctx.hdxClaimableAmountFormatted.base != '0']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, " HDX Already claimed ", 512), [
      [_vShow, _ctx.hdxClaimableAmountFormatted.base == '0']
    ])
  ]))
}