import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "xhdx-amounts-indicator" }
const _hoisted_2 = { class: "amount-details" }
const _hoisted_3 = { class: "amount-details-item" }
const _hoisted_4 = { class: "amount-details-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("span", null, "Bought:", -1)),
        _createElementVNode("span", null, _toDisplayString(_ctx.xhdxBoughtBalanceFormatted) + " xHDX", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("span", null, "Gas Refund:", -1)),
        _createElementVNode("span", null, _toDisplayString(_ctx.xhdxGasRefundBalanceFormatted) + " xHDX", 1)
      ])
    ])
  ]))
}