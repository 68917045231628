import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/eye-logo.png'


const _hoisted_1 = { class: "hdx-claim-main-container" }
const _hoisted_2 = { class: "hdx-claim-inner-container" }
const _hoisted_3 = { class: "content-container" }
const _hoisted_4 = { class: "wizard-steps-container" }
const _hoisted_5 = { class: "wizard-progress-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressLine = _resolveComponent("ProgressLine")!
  const _component_LoadingCover = _resolveComponent("LoadingCover")!
  const _component_WizardStep1 = _resolveComponent("WizardStep1")!
  const _component_WizardStep2 = _resolveComponent("WizardStep2")!
  const _component_WizardStep3 = _resolveComponent("WizardStep3")!
  const _component_WizardStep4 = _resolveComponent("WizardStep4")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("header", { class: "main-header" }, [
        _createElementVNode("a", { href: "/" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "claim.hydradx.io"
          }),
          _createElementVNode("h1", null, "claim.hydradx.io"),
          _createElementVNode("a", {
            class: "help",
            href: "https://docs.hydradx.io/claim/"
          }, "HELP?")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ProgressLine, {
              step: _ctx.wizardState.wizardStep
            }, null, 8, ["step"])
          ]),
          _createVNode(_component_LoadingCover, {
            "wizard-state": _ctx.wizardState,
            "hdx-account-data": _ctx.hdxAccountData,
            "on-reconnect-click": _ctx.onReconnectClick,
            "on-connect-polkadot-ext": _ctx.onConnectPolkadotExt,
            "on-install-pd-ext-click": _ctx.onInstallPdExtClick
          }, null, 8, ["wizard-state", "hdx-account-data", "on-reconnect-click", "on-connect-polkadot-ext", "on-install-pd-ext-click"]),
          (_ctx.wizardState.wizardStep === 1)
            ? (_openBlock(), _createBlock(_component_WizardStep1, {
                key: 0,
                "wizard-state": _ctx.wizardState,
                "eth-account-data": _ctx.ethAccountData,
                "on-connect-eth-account": _ctx.onConnectEthAccount,
                "is-next-step-valid": _ctx.isNextStepValid,
                "next-step-click": _ctx.nextStepClick
              }, null, 8, ["wizard-state", "eth-account-data", "on-connect-eth-account", "is-next-step-valid", "next-step-click"]))
            : _createCommentVNode("", true),
          (_ctx.wizardState.wizardStep === 2)
            ? (_openBlock(), _createBlock(_component_WizardStep2, {
                key: 1,
                "wizard-state": _ctx.wizardState,
                "eth-account-data": _ctx.ethAccountData,
                "hdx-account-data": _ctx.hdxAccountData,
                "on-connect-hdx-account": _ctx.onConnectHdxAccount,
                "is-next-step-valid": _ctx.isNextStepValid,
                "next-step-click": _ctx.nextStepClick,
                "go-to-step": _ctx.goToStep,
                "set-global-notice": _ctx.setGlobalNotice
              }, null, 8, ["wizard-state", "eth-account-data", "hdx-account-data", "on-connect-hdx-account", "is-next-step-valid", "next-step-click", "go-to-step", "set-global-notice"]))
            : _createCommentVNode("", true),
          (_ctx.wizardState.wizardStep === 3)
            ? (_openBlock(), _createBlock(_component_WizardStep3, {
                key: 2,
                "wizard-state": _ctx.wizardState,
                "eth-account-data": _ctx.ethAccountData,
                "hdx-account-data": _ctx.hdxAccountData,
                "on-connect-hdx-account": _ctx.onConnectHdxAccount,
                "is-next-step-valid": _ctx.isNextStepValid,
                "next-step-click": _ctx.nextStepClick,
                "set-claim-process-status": _ctx.setClaimProcessStatus
              }, null, 8, ["wizard-state", "eth-account-data", "hdx-account-data", "on-connect-hdx-account", "is-next-step-valid", "next-step-click", "set-claim-process-status"]))
            : _createCommentVNode("", true),
          (_ctx.wizardState.wizardStep === 4)
            ? (_openBlock(), _createBlock(_component_WizardStep4, {
                key: 3,
                "wizard-state": _ctx.wizardState,
                "hdx-account-data": _ctx.hdxAccountData
              }, null, 8, ["wizard-state", "hdx-account-data"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}