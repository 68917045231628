import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "loading-cover-message" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", { class: "global-notice-message" }, _toDisplayString(_ctx.wizardState.globalNotice.message), 513), [
      [_vShow, 
        !_ctx.wizardState.loading &&
        !_ctx.wizardState.isReconnectBtn &&
        !_ctx.wizardState.claiming.inProgress &&
        _ctx.wizardState.globalNotice.open
      ]
    ]),
    _withDirectives(_createElementVNode("div", null, _cache[3] || (_cache[3] = [
      _createElementVNode("p", null, " Due to the Genesis 2 transition of the HydraDX Snakenet the transactions are temporarily disabled which also prevents you from claiming. ", -1),
      _createElementVNode("p", null, [
        _createTextVNode(" Check for updates "),
        _createElementVNode("a", {
          href: "https://twitter.com/hydra_dx",
          title: "Twitter"
        }, [
          _createElementVNode("u", null, "on our twitter")
        ]),
        _createTextVNode(". ")
      ], -1)
    ]), 512), [
      [_vShow, _ctx.wizardState.tempDisabled]
    ]),
    _withDirectives(_createElementVNode("div", null, " Loading ... ", 512), [
      [_vShow, 
        _ctx.wizardState.loading &&
        !_ctx.wizardState.isReconnectBtn &&
        !_ctx.wizardState.claiming.inProgress &&
        !_ctx.wizardState.tempDisabled
      ]
    ]),
    _withDirectives(_createElementVNode("div", null, " Claiming ... ", 512), [
      [_vShow, !_ctx.wizardState.isReconnectBtn && _ctx.wizardState.claiming.inProgress]
    ]),
    _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.wizardState.claiming.processMessage), 513), [
      [_vShow, 
        !_ctx.wizardState.isReconnectBtn &&
        _ctx.wizardState.claiming.inProgress &&
        _ctx.wizardState.claiming.processMessage.length > 0
      ]
    ]),
    _withDirectives(_createElementVNode("a", {
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onReconnectClick && _ctx.onReconnectClick(...args)), ["prevent"])),
      class: "hdx-btn loading-cover-btn reconnect-btn"
    }, "Reconnect", 512), [
      [_vShow, _ctx.wizardState.isReconnectBtn]
    ]),
    (
        !_ctx.wizardState.isReconnectBtn &&
        !_ctx.wizardState.loading &&
        !_ctx.wizardState.claiming.inProgress &&
        !_ctx.hdxAccountData.isPolkadotExtAvailable &&
        _ctx.loadingCoverState.isInjectedWeb3
      )
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "hdx-btn loading-cover-btn",
          href: "#",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onConnectPolkadotExt && _ctx.onConnectPolkadotExt(...args)), ["prevent"]))
        }, "Connect Polkadot.js"))
      : _createCommentVNode("", true),
    (
        !_ctx.wizardState.isReconnectBtn &&
        !_ctx.wizardState.loading &&
        !_ctx.wizardState.claiming.inProgress &&
        !_ctx.hdxAccountData.isPolkadotExtAvailable &&
        !_ctx.loadingCoverState.isInjectedWeb3
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Please use Chrome or Firefox with respective polkadot{.js} "))
      : _createCommentVNode("", true),
    (
        !_ctx.wizardState.isReconnectBtn &&
        !_ctx.wizardState.loading &&
        !_ctx.wizardState.claiming.inProgress &&
        !_ctx.hdxAccountData.isPolkadotExtAvailable &&
        !_ctx.loadingCoverState.isInjectedWeb3
      )
      ? (_openBlock(), _createElementBlock("a", {
          key: 2,
          class: "hdx-btn loading-cover-btn",
          target: "_blank",
          href: "https://polkadot.js.org/extension/",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInstallPdExtClick && _ctx.onInstallPdExtClick(...args)))
        }, "Install Polkadot.js"))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, 
      _ctx.wizardState.loading ||
      _ctx.wizardState.claiming.inProgress ||
      !_ctx.hdxAccountData.isPolkadotExtAvailable ||
      _ctx.wizardState.globalNotice.open
    ]
  ])
}