import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wizard-step-container step-3" }
const _hoisted_2 = { class: "sign-message-container" }
const _hoisted_3 = { class: "copy-action-btn" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "sign-message" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "sign-message-help" }, [
      _createElementVNode("a", {
        href: "https://docs.hydradx.io/claim#03-sign",
        target: "_blank"
      }, "ADDRESS DOESN'T MATCH? ")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("img", {
          src: _ctx.copyIconSrc,
          alt: "Copy"
        }, null, 8, _hoisted_4), [
          [_vShow, !_ctx.step3State.copied]
        ]),
        _withDirectives(_createElementVNode("img", {
          src: _ctx.checkedIconSrc,
          alt: "Copy"
        }, null, 8, _hoisted_5), [
          [_vShow, _ctx.step3State.copied]
        ])
      ], 512), [
        [_vShow, _ctx.ethAccountData.accountConnectionMethod === 'manual']
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.step3State.messageValue), 1)
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      class: "hdx-input response-input",
      placeholder: "Response...",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.step3State.responseValue) = $event)),
      disabled: 
        _ctx.step3State.isResponseInputDisabled ||
        _ctx.ethAccountData.accountConnectionMethod === 'metamask'
      
    }, null, 8, _hoisted_7), [
      [_vModelText, _ctx.step3State.responseValue]
    ]),
    _withDirectives(_createElementVNode("a", {
      class: "hdx-btn next-step",
      href: "#",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSignMessageClick && _ctx.onSignMessageClick(...args)), ["prevent"]))
    }, "SIGN ", 512), [
      [_vShow, 
        _ctx.ethAccountData.isMetamaskAvailable &&
        _ctx.step3State.responseValueForSend.length === 0
      ]
    ]),
    _withDirectives(_createElementVNode("a", {
      class: _normalizeClass([{ disabled: !_ctx.step3State.isSignatureValid }, "hdx-btn next-step"]),
      href: "#",
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClaimClick && _ctx.onClaimClick(...args)), ["prevent"]))
    }, _toDisplayString((!_ctx.step3State.isSignatureValid &&
          _ctx.step3State.responseValueForSend.length === 0) ||
        (_ctx.step3State.isSignatureValid &&
          _ctx.step3State.responseValueForSend.length > 0)
          ? 'CLAIM'
          : 'Signature in not valid'), 3), [
      [_vShow, 
        !_ctx.ethAccountData.isMetamaskAvailable ||
        _ctx.step3State.responseValueForSend.length > 0
      ]
    ])
  ]))
}